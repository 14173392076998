<template>
  <div>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-card elevation="0">
        <v-card-actions>
          <v-card-title>{{ formTitle }}</v-card-title>
          <v-spacer></v-spacer>
          <v-btn :to="{ name: 'Activities' }" color="green" class="" rounded>
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn :disabled="!valid" color="primary" @click="save(true)" rounded>
            {{ $t("Save") }}
          </v-btn>
        </v-card-actions>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      :label="$t('Select a Date')"
                      required
                      v-bind="attrs"
                      v-on="on"
                      :rules="rules.date"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateFormatted"
                    no-title
                    @input="menu2 = false"
                    :first-day-of-week="1"
                    v-on:change="getMonthlyProgress"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col
                v-if="
                  $store.state.user.user.role == 0 ||
                  $store.state.user.user.role == 1
                "
                cols="12"
                sm="6"
                md="6"
              >
                <v-select
                  :items="users"
                  item-text="fullName"
                  item-value="id"
                  v-model="item.userId"
                  :label="$t('Select an Account Manager')"
                  :rules="rules.userId"
                  required
                  v-on:change="getMonthlyProgress"
                ></v-select>
              </v-col>
            </v-row>
            <v-row
              v-for="activityType in activityTypes.filter((a) => a.visible)"
              v-bind:key="activityType.id"
              class="mb-1 mb-sm-0"
            >
              <v-col cols="12" sm="6" md="6" class="custom-col">
                <p class="activity-name mb-0">
                  <b>{{ activityType.name }}</b>
                </p>
                <div
                  class="mb-2"
                  v-html="
                    getMonthlyProgressLabel(
                      activityType.id,
                      activities[activityType.id].amount
                    )
                  "
                ></div>
                <v-text-field
                  v-model="activities[activityType.id].activityTypeId"
                  v-show="false"
                  value="activityType.id"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6" class="custom-col">
                <div class="d-flex activities-amount float-left mr-4">
                  <v-btn
                    v-on:click="decrease(activityType.id)"
                    class="mp-btn mr-1"
                    rounded
                    color="green"
                    >-</v-btn
                  >
                  <v-text-field
                    v-model.number="activities[activityType.id].amount"
                    type=""
                    min="0"
                    required
                    :ref="activityType.id"
                    :key="componentKey"
                    class="number"
                    outlined
                    dense
                  ></v-text-field>
                  <v-btn
                    v-on:click="increase(activityType.id)"
                    class="mp-btn ml-1"
                    rounded
                    color="primary"
                    >+</v-btn
                  >
                </div>
                <v-btn
                  fab
                  color="primary"
                  small
                  class="float-left mr-4"
                  elevation="2"
                  :disabled="activities[activityType.id].amount <= 0"
                  @click="setActivitiesRelationsDialog(activityType.id)"
                >
                  <v-icon small> mdi-account-group-outline </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-form>

    <v-dialog
      v-if="activityMessage"
      v-model="showActivityMessageDialog"
      max-width="600px"
      @input="(v) => v || goToActivities()"
    >
      <v-card>
        <v-card-title class="text-center">
          <v-container>
            <span class="text-h5 p-5">{{ $t(activityMessage.message) }}</span>
            <v-btn
              icon
              absolute
              top
              right
              style="right: 5px; top: 5px"
              @click="goToActivities()"
            >
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-container>
        </v-card-title>
        <v-card-text class="text-center">
          <v-container
            class="mt-4 mb-4 emoji-container"
            v-html="activityMessage.emoji.code"
          >
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="
        activitiesRelationsDialogActivityTypeId && activitiesRelationsDialog
      "
      v-model="activitiesRelationsDialog"
      max-width="600px"
      ref="relationsDialog"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ $t("Link relations to activities") }}</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="relationsForm">
            <v-autocomplete
              v-for="index in activities[
                activitiesRelationsDialogActivityTypeId
              ].amount"
              :key="index"
              :rules="rules.relation"
              v-model="
                activityRelations[activitiesRelationsDialogActivityTypeId]
                  .relationIds[index - 1]
              "
              :items="relations"
              item-text="name"
              item-value="id"
              :label="$t('Select Relation')"
              required
              clearable
              :search-input.sync="typedValues[index]"
              menu-props="closeOnContentClick"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title
                      v-if="typedValues[index] && typedValues[index].length > 2"
                    >
                      <v-icon small color="primary" class="mr-1">
                        mdi-plus
                      </v-icon>
                      <span
                        @click="addNewRelation(index)"
                        class="
                          new-relation-link
                          text-decoration-underline
                          primary--text
                        "
                        >{{ $t("Add as new relation") }}</span
                      >
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      {{ $t("No relations available") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" text @click="resetRelations()">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn color="primary" @click="setRelations()">
            {{ $t("Save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      componentKey: 0,
      valid: true,
      item: {},
      monthlyProgress: [],
      menu2: false,
      dateFormatted: new Date().toISOString().substr(0, 10),
      date: this.formatDate(new Date().toISOString().substr(0, 10)),
      activities: {},
      activityRelations: {},
      users: [],
      activityTypes: [],
      id: "",
      loading: false,
      rules: {
        userId: [(value) => !!value || this.$t("Required")],
        date: [(value) => !!value || this.$t("Required")],
        relation: [(value) => !!value || this.$t("Required")],
      },
      activityMessage: null,
      showActivityMessageDialog: false,
      activitiesRelationsDialog: false,
      activitiesRelationsDialogActivityTypeId: null,
      relations: null,
      typedValues: [],
    };
  },

  computed: {
    formTitle() {
      return this.id == ""
        ? this.$t("Create New Activity")
        : this.$t("Edit Activity");
    },
  },
  watch: {
    dateFormatted() {
      this.date = this.formatDate(this.dateFormatted);
    },
  },
  created: function () {
    var update = false;
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      update = true;
    }
    this.fetchUsers();
    this.fetchActivityTypes(update);
    this.filterActivityTypes();
    this.fetchRelations();
  },

  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    formatDateInverse(date) {
      if (!date) return null;

      const [day, month, year] = date.split("-");
      return `${year}-${month}-${day}`;
    },
    increase(v) {
      this.activities[v].amount = parseInt(this.activities[v].amount) + 1;
      this.componentKey += 1;
    },
    decrease(v) {
      this.activities[v].amount =
        parseInt(this.activities[v].amount) <= 0
          ? 0
          : parseInt(this.activities[v].amount) - 1;
      this.componentKey += 1;
    },
    fetchUsers() {
      let uri = this.$urlPrefix + "/User/CountAll?includeDeleted=false";
      this.axios.get(uri).then((response) => {
        let uri2 =
          this.$urlPrefix +
          "/User/GetList?start=0&count=" +
          response.data +
          "&includeDeleted=false";
        this.axios.get(uri2).then((response) => {
          this.users = response.data.filter(function (user) {
            return user.role == 1 || user.role == 2;
          });
        });
      });
    },
    fetchActivityTypes(update = null) {
      let uri = this.$urlPrefix + "/ActivityType/CountAll";
      this.axios.get(uri).then((response) => {
        let uri2 =
          this.$urlPrefix +
          "/ActivityType/GetList?start=0&count=" +
          response.data;
        this.axios.get(uri2).then((response) => {
          this.activityTypes = response.data;
          this.activityTypes.forEach((activityType) => {
            this.activities[activityType.id] = {
              activityTypeId: activityType.id,
              amount: 0,
            };
            this.activityRelations[activityType.id] = {
              activityTypeId: activityType.id,
              amount: 0,
            };
          });
          if (update) {
            this.getItem();
          } else {
            this.getMonthlyProgress();
          }
          this.loading = false;
        });
      });
    },
    fetchRelations() {
      let uri = this.$urlPrefix + "/Relation/CountAll?all=true";
      this.axios.get(uri).then((response) => {
        let uri2 =
          this.$urlPrefix +
          "/Relation/GetList?start=0&all=true&count=" +
          response.data;
        this.axios.get(uri2).then((response) => {
          this.relations = response.data;
        });
      });
    },
    getItem() {
      this.loading = true;
      let uri = this.$urlPrefix + "/Activity/Get?id=" + this.id;
      this.axios.get(uri).then((response) => {
        this.item = response.data;
        this.date = this.formatDate(
          new Date(this.item.date).toISOString().substr(0, 10)
        );
        this.dateFormatted = new Date(this.item.date)
          .toISOString()
          .substr(0, 10);
        this.item.activities.forEach((activity) => {
          this.activities[activity.activityTypeId] = activity;
          this.activityRelations[activity.activityTypeId] = JSON.parse(
            JSON.stringify(activity)
          );
        });
        this.getMonthlyProgress();
        this.filterActivityTypes();
        this.loading = false;
      });
    },
    getMonthlyProgress() {
      if (this.item.userId && this.dateFormatted) {
        let uri =
          this.$urlPrefix +
          "/Activity/getMonthlyProgress?accountManagerId=" +
          this.item.userId +
          "&date=" +
          this.dateFormatted +
          "&activityId=" +
          this.id;
        this.axios.get(uri).then((response) => {
          this.monthlyProgress = response.data;
          this.filterActivityTypes();
        });
      }
    },
    save(goToOverview) {
      if (!goToOverview) {
        this.activitiesRelationsDialog = false;
      }
      var scope = this;
      this.item.activities = [];
      for (var activity in this.activities) {
        this.item.activities.push(this.activities[activity]);
      }
      this.item.date = this.dateFormatted;
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.id != "") {
          let uri = this.$urlPrefix + "/Activity/Update/";
          this.axios.put(uri, this.item).then((response) => {
            if (response.status == 204 || response.status == 200) {
              this.loading = false;

              if (!response.data.emoji) {
                if (goToOverview) {
                  scope.$store.dispatch("setSnack", {
                    icon: "mdi-thumb-up",
                    color: "green",
                    msg: this.$t("Thanks for adding your activities!"),
                  });
                  this.$router.push({
                    name: "Activities",
                    query: { page: this.$route.query.page },
                  });
                }
              } else {
                this.showActivityMessageDialog = true;
                this.activityMessage = response.data;
              }
            }
          });
        } else {
          let uri = this.$urlPrefix + "/Activity/Add";
          this.axios.post(uri, this.item).then((response) => {
            if (response.status == 200) {
              this.item = {};
              this.loading = false;

              if (!response.data.emoji) {
                if (goToOverview) {
                  scope.$store.dispatch("setSnack", {
                    icon: "mdi-thumb-up",
                    color: "green",
                    msg: this.$t("Thanks for adding your activities!"),
                  });
                  this.$router.push({
                    name: "Activities",
                    query: { page: this.$route.query.page },
                  });
                }
              } else {
                this.showActivityMessageDialog = true;
                this.activityMessage = response.data;
              }
            }
          });
        }
      }
    },
    setRelations() {
      if (this.$refs.relationsForm.validate()) {
        this.activitiesRelationsDialog = false;
        for (var id in this.activityRelations) {
          if (this.activityRelations[id].relationIds) {
            this.activities[id].relationIds = JSON.parse(
              JSON.stringify(this.activityRelations[id].relationIds)
            );
          }
        }
      }
    },
    resetRelations() {
      this.activitiesRelationsDialog = false;
      for (var id in this.activities) {
        if (this.activities[id].relationIds) {
          this.activityRelations[id].relationIds = JSON.parse(
            JSON.stringify(this.activities[id].relationIds)
          );
        }
      }
    },
    goToActivities() {
      this.showActivityMessageDialog = false;
      this.$router.push({
        name: "Activities",
        query: { page: this.$route.query.page },
      });
    },
    getMonthlyProgressLabel(activityTypeId, amount) {
      var currentMontlyProgress = this.monthlyProgress.find((obj) => {
        return obj.activityTypeId == activityTypeId;
      });

      if (currentMontlyProgress) {
        if (currentMontlyProgress.goal > 0) {
          return (
            amount +
            currentMontlyProgress.amount +
            " " +
            this.$t("of") +
            " " +
            currentMontlyProgress.goal +
            " in " +
            this.getMonthString(this.dateFormatted)
          );
        } else if (this.item.userId) {
          return this.$t("No goal set.");
        } else {
          return "";
        }
      } else if (this.item.userId) {
        return this.$t("No goal set.");
      } else {
        return "";
      }
    },
    getMonthString(date) {
      var month = new Date(date).getMonth();
      var objDate = new Date();
      objDate.setDate(1);
      objDate.setMonth(month);

      var locale = process.env.NODE_ENV === "development" ? "en-us" : "nl-be",
        stringMonth = objDate.toLocaleString(locale, { month: "long" });

      return stringMonth;
    },
    setActivitiesRelationsDialog(activityTypeId) {
      this.activitiesRelationsDialogActivityTypeId = activityTypeId;
      if (!this.activityRelations[activityTypeId].relationIds) {
        this.activityRelations[activityTypeId].relationIds = [];
      }
      this.activitiesRelationsDialog = !this.activitiesRelationsDialog;
    },
    filterActivityTypes() {
      if (
        this.item.userId == undefined &&
        this.$store.state.user.user.role == 2
      ) {
        this.item.userId = this.$store.state.user.user.id;
      }

      var newActivityTypes = [];
      this.activityTypes.forEach((activityType) => {
        if (this.item.userId == undefined) {
          activityType.visible = true;
        } else if (activityType.selectedUserIds == null) {
          activityType.visible = true;
        } else if (activityType.selectedUserIds.includes(this.item.userId)) {
          activityType.visible = true;
        } else if (this.item.activities) {
          var activity = this.item.activities.find(
            (a) => a.activityTypeId == activityType.id
          );
          if (activity != null && activity != undefined) {
            if (activity.amount > 0) {
              activityType.visible = true;
            } else {
              activityType.visible = false;
            }
          } else {
            activityType.visible = false;
          }
        } else {
          activityType.visible = false;
        }
        newActivityTypes.push(activityType);
      });
      this.activityTypes = newActivityTypes;
    },
    addNewRelation(index) {
      let uri = this.$urlPrefix + "/Relation/Add";
      this.axios.post(uri, { name: this.typedValues[index], accountManagerId: this.item.userId }).then((response) => {
        if (response.status == 200) {
          this.relations.push(response.data)
          this.activityRelations[this.activitiesRelationsDialogActivityTypeId]
                  .relationIds[index - 1] = response.data.id
        }
      });
    },
  },
};
</script>
<style lang="scss">
.activities-amount {
  width: 190px;
}
.number input {
  text-align: center;
}
.mp-btn {
  height: 40px !important;
}
.mp-btn span.v-btn__content {
  font-size: 20px;
}
.v-card__title {
  word-break: normal !important;
}
.emoji-container {
  font-size: 7rem;
  min-height: 7rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.new-relation-link {
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .custom-col {
    padding-top: 0;
    padding-bottom: 0;
  }
}
</style>
